import { Grid, Button, Typography } from "@mui/material";
import React from "react";
import { logoutKeycloak } from "../../../../modules/AuthProvider";

const Logout = () => {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        px: { xs: 2.5, md: 5 },
        pb: { xs: 2.5, md: 5 },
        pt: { xs: 0, md: 0.5 },
        display: {
          xs: "none",
          md: "block",
        },
      }}
    >
      <Button
        data-testid={"logout-btn"}
        sx={{
          border: "1px solid #979797",
          borderRadius: 1,
          color: "#524961",
          fontWeight: "600",
          p: 1,
        }}
        onClick={() => logoutKeycloak('/')}
        fullWidth
      >
        <Typography textAlign="center" variant="body2">
          wyloguj się
        </Typography>
      </Button>
    </Grid>
  );
};
export default Logout;
