import React from "react";

import { Navigate, useRoutes } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import CreateGame from "./views/CreateGame";
import MainLayout from "./layout/MainLayout";

export default function DashboardRoutes() {

    return useRoutes([
        {
            path: "/",
            element: <MainLayout />,
            children: [
                {
                    path: "/",
                    element: (
                        <Dashboard title="EffeCarts" />
                    ),
                },
                {
                    path: "/tworzenie-gry",
                    element: (
                        <CreateGame
                            title="Tworzenie gry"
                        />
                    ),
                },
            ],
        },
        {
            path: "*",
            element: <Navigate replace to="/" />,
        },
    ]);
}
