import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { aleAccountApi } from "../views/aleAccountApi";
import { aleCashbackApi } from "../views/aleCashbackApi";
import { aleWebApi } from "../views/aleWebApi";

const reducers = combineReducers({
  [aleAccountApi.reducerPath]: aleAccountApi.reducer,
  [aleCashbackApi.reducerPath]: aleCashbackApi.reducer,
  [aleWebApi.reducerPath]: aleWebApi.reducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      aleAccountApi.middleware,
      aleCashbackApi.middleware,
      aleWebApi.middleware
    );
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
