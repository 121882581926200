import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import keycloak from "../keycloak";
import { GetBankTransferDetailsInterface } from "../interfaces/GetBankTransferDetailsInterface";
import { AddOrUpdateBankTransferDetailsInterface } from "../interfaces/AddOrUpdateBankTransferDetailsInterface";

export const aleAccountApi = createApi({
  reducerPath: "aleAccount",
  tagTypes: [
    "cashback-registered-notify",
    "status-changed-notify",
    "payout-notify",
    "info-about-coupons-and-promotions",
    "info-about-news-and-tips-notify",
    "bank-transfer-details",
    "installed-app",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_ACCOUNT_API_URL}/v1`,
    prepareHeaders: (headers) => {
      const token = keycloak.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    removeAccount: build.mutation<void, void>({
      query: () => {
        return {
          url: `/user/remove_account`,
          method: "DELETE",
        };
      },
    }),
    getPersonalData: build.query<any, void>({
      query: () => `/user/get_personal_data`,
    }),
    editUserInstalledApp: build.mutation<void, void>({
      query: () => {
        return {
          url: `/user-options/installed-app`,
          method: "POST",
        };
      },
      invalidatesTags: () => [{ type: "installed-app" }],
    }),
    getUserInstalledApp: build.query<boolean, void>({
      query: () => `/user-options/installed-app`,
      providesTags: () => [{ type: "installed-app" }],
    }),
    editCashbackRegisteredNotify: build.mutation<void, boolean>({
      query: (value) => {
        return {
          url: `/user-options/cashback-registered-notify`,
          method: "PUT",
          body: { value },
        };
      },
      invalidatesTags: () => [{ type: "cashback-registered-notify" }],
    }),
    getCashbackRegisteredNotify: build.query<boolean, void>({
      query: () => `/user-options/cashback-registered-notify`,
      providesTags: () => [{ type: "cashback-registered-notify" }],
    }),
    editStatusChangedNotify: build.mutation<void, boolean>({
      query: (value) => {
        return {
          url: `/user-options/status-changed-notify`,
          method: "PUT",
          body: { value },
        };
      },
      invalidatesTags: () => [{ type: "status-changed-notify" }],
    }),
    getStatusChangedNotify: build.query<boolean, void>({
      query: () => `/user-options/status-changed-notify`,
      providesTags: () => [{ type: "status-changed-notify" }],
    }),
    editPayoutNotify: build.mutation<void, boolean>({
      query: (value) => {
        return {
          url: `/user-options/payout-notify`,
          method: "PUT",
          body: { value },
        };
      },
      invalidatesTags: () => [{ type: "payout-notify" }],
    }),
    getPayoutNotify: build.query<boolean, void>({
      query: () => `/user-options/payout-notify`,
      providesTags: () => [{ type: "payout-notify" }],
    }),
    editInfoAboutCouponsAndPromotionsNotify: build.mutation<void, boolean>({
      query: (value) => {
        return {
          url: `/user-options/info-about-coupons-and-promotions-notify`,
          method: "PUT",
          body: { value },
        };
      },
      invalidatesTags: () => [{ type: "info-about-coupons-and-promotions" }],
    }),
    getInfoAboutCouponsAndPromotionsNotify: build.query<boolean, void>({
      query: () => `/user-options/info-about-coupons-and-promotions-notify`,
      providesTags: () => [{ type: "info-about-coupons-and-promotions" }],
    }),
    editInfoAboutNewsAndTipsNotify: build.mutation<void, boolean>({
      query: (value) => {
        return {
          url: `/user-options/info-about-news-and-tips-notify`,
          method: "PUT",
          body: { value },
        };
      },
      invalidatesTags: () => [{ type: "info-about-news-and-tips-notify" }],
    }),
    getInfoAboutNewsAndTipsNotify: build.query<boolean, void>({
      query: () => `/user-options/info-about-news-and-tips-notify`,
      providesTags: () => [{ type: "info-about-news-and-tips-notify" }],
    }),
    addBankTransferDetails: build.mutation<
      GetBankTransferDetailsInterface,
      AddOrUpdateBankTransferDetailsInterface
    >({
      query: (body) => {
        return {
          url: `/bank-transfer-details`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: () => [{ type: "bank-transfer-details" }],
    }),
    editBankTransferDetails: build.mutation<
      GetBankTransferDetailsInterface,
      Pick<GetBankTransferDetailsInterface, "id"> &
        AddOrUpdateBankTransferDetailsInterface
    >({
      query: ({ id, ...body }) => {
        return {
          url: `/bank-transfer-details/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: () => [{ type: "bank-transfer-details" }],
    }),
    getBankTransferDetails: build.query<
      GetBankTransferDetailsInterface[],
      void
    >({
      query: () => `/bank-transfer-details`,
      providesTags: () => [{ type: "bank-transfer-details" }],
    }),
    emailVerification: build.mutation({
      query: (params) => {
        return {
          url: `/user/email-verification`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useRemoveAccountMutation,
  useGetPersonalDataQuery,
  useGetUserInstalledAppQuery,
  useEditUserInstalledAppMutation,
  useGetCashbackRegisteredNotifyQuery,
  useEditCashbackRegisteredNotifyMutation,
  useGetStatusChangedNotifyQuery,
  useEditStatusChangedNotifyMutation,
  useGetPayoutNotifyQuery,
  useEditPayoutNotifyMutation,
  useGetInfoAboutCouponsAndPromotionsNotifyQuery,
  useEditInfoAboutCouponsAndPromotionsNotifyMutation,
  useGetInfoAboutNewsAndTipsNotifyQuery,
  useEditInfoAboutNewsAndTipsNotifyMutation,
  useGetBankTransferDetailsQuery,
  useAddBankTransferDetailsMutation,
  useEditBankTransferDetailsMutation,
  useEmailVerificationMutation,
} = aleAccountApi;
