import React, { useEffect } from "react";
import { Box } from "@mui/material";

const Index = ({ title }: { title: string }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Box>
        Główna strong
    </Box>
  );
};

export default Index;
