import {createTheme} from "@mui/material";

export default createTheme({
    typography: {
        fontFamily: "inherit",
        subtitle1: {
            fontSize: 18,
            lineHeight: 1.4,
        },
    },
    palette: {
        text: {
            primary: "#524962",
        },
        primary: {
            main: "#E42D31",
        },
        background: {
            paper: "#F4F4F5",
        },
    },
    shape: {
        borderRadius: 6,
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: "#524962",
                    minHeight: "inherit",
                    backgroundColor: "transparent !important",
                    "&.MuiMenuItem-divider": {
                        borderBottomColor: "#D8D8D8",
                        borderWidth: "2px",
                    },
                    "&.Mui-selected": {
                        borderBottomColor: "#E42D31",
                        backgroundColor: "transparent !important",
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        padding: "5.5px 9px",
                    },
                    "& .MuiAutocomplete-root:focus .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #000 !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #d4d2d8",
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: "unset",
                    "& .MuiTabs-indicator": {
                        display: "none",
                    },
                    "& .MuiTabs-flexContainer": {
                        padding: "2px 20px 21px",
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    background: "#fff",
                    borderRadius: "16px",
                    minHeight: "unset",
                    minWidth: "unset",
                    padding: "7px 13px",
                    textTransform: "none",
                    fontSize: "12px",
                    marginRight: "10px",
                    color: "#524962",
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                    "&.Mui-selected": {
                        background:
                            "linear-gradient(224.15deg, #FF0065 0%, #E42D31 98.56%), #E42D31;",
                        color: "#fff",
                        boxShadow: "none",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: "#fff",
                        opacity: "0.6",
                    },
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    "& .MuiPagination-ul": {
                        flexWrap: "nowrap",
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                    "&.Mui-selected": {
                        color: "white",
                        background:
                            "linear-gradient(224.15deg, #FF0065 0%, #E42D31 98.56%);",
                    },
                    "&.MuiPaginationItem-ellipsis": {
                        minWidth: "unset",
                        padding: 0,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    borderColor: "#000",
                },
                root: {
                    borderColor: "#000",
                },
            },
        },
    },
});