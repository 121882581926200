import React from 'react';
import './App.css';
import DashboardRoutes from "./routes";
import {BrowserRouter} from "react-router-dom";
import {ScopedCssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import {Provider} from "react-redux";
import {store} from "./app/store";
import {AuthProvider} from "./modules/AuthProvider";

function App() {
  return (
      <Provider store={store}>
          <AuthProvider>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <ScopedCssBaseline>
                  <DashboardRoutes />
                </ScopedCssBaseline>
              </ThemeProvider>
            </BrowserRouter>
          </AuthProvider>
      </Provider>
  );
}

export default App;
