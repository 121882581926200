import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { ShopFromWebInterface } from "../interfaces/ShopFromWebInterface"

export const aleWebApi = createApi({
  reducerPath: "aleWebApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_WEB_API_URL}/api/v1`,
  }),
  endpoints: (build) => ({
    getShops: build.query<ShopFromWebInterface[], { search: string }>({
      query: (params) => {
        return `/shops?search=${encodeURIComponent(params.search)}&limit=5&orderBy=entries&orderDir=desc`
      },
    }),
  }),
})

export const { useGetShopsQuery } = aleWebApi
