import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import React from "react";

const MainContent = () => {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        borderRadius: 1,
        minHeight: 1,
      }}
    >
      <Outlet />
    </Box>
  );
};
export default MainContent;
