import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import keycloak from "../keycloak";
import { UserTransactionList } from "../interfaces/UserTransaction";
import { PayoutInterface } from "../interfaces/PayoutInterface";

export const aleCashbackApi = createApi({
  reducerPath: "aleCashbackApi",
  tagTypes: ["cashback-balance", "cashback-transactions"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_CASHBACK_API_URL}/v1`,
    prepareHeaders: (headers) => {
      const token = keycloak.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUserTransactions: build.query<
      UserTransactionList,
      { page: number; selectedTab: number }
    >({
      query: (params) => {
        if (params.selectedTab > 0) {
          return `/user-transactions?state=${params.selectedTab}&page=${params.page}&limit=10`;
        } else {
          return `/user-transactions?page=${params.page}&limit=10`;
        }
      },
      providesTags: () => [{ type: "cashback-transactions" }],
    }),
    getUserTransactionsBalance: build.query<any, void>({
      query: () => `/user-transactions/balance`,
      providesTags: () => [{ type: "cashback-balance" }],
    }),
    addPayout: build.mutation<void, PayoutInterface>({
      query: (body) => {
        return {
          url: `/payout`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: () => ["cashback-balance", "cashback-transactions"],
    }),
  }),
});

export const {
  useGetUserTransactionsQuery,
  useGetUserTransactionsBalanceQuery,
  useAddPayoutMutation,
} = aleCashbackApi;
