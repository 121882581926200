import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import keycloak from "../../../../keycloak";
import { useGetUserTransactionsBalanceQuery } from "../../../../views/aleCashbackApi";
import parseJwt from "../../../../utils/parseJwt";

const AccountData = () => {

  const {
    data: cashbackData,
    isLoading: isCashBackDataLoading,
    error: cashbackDataError,
  } = useGetUserTransactionsBalanceQuery();

  return (
    <Box
      sx={{
        py: {
          xs: 1,
          md: 2.5,
        },
        px: {
          xs: 2.5,
          md: 5,
        },
        pb: {
          xs: 0.7,
          md: 0,
        },
      }}
    >
      {isCashBackDataLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Typography
            variant="h5"
            sx={{
              pt: {
                xs: 1.5,
                md: 2.5,
              },
              pb: {
                xs: 1,
                md: 2.5,
              },
            }}
          >
            Witaj{" "}
            <Typography
              data-testid={"clientname"}
              component="span"
              variant="h5"
              sx={{
                fontWeight: "700",
              }}
            >
              {parseJwt(keycloak.token).given_name}
            </Typography>
          </Typography>
          <Typography
            variant="body2"
            sx={{
              py: {
                xs: 0.5,
                md: 0.5,
              },
            }}
          >
            Bilans konta
          </Typography>
          <Typography
            data-testid={"account-balance"}
            sx={{
              fontSize: "36px",
              fontWeight: "800",
              lineHeight: "1",
              py: {
                xs: 0,
              },
            }}
          >
            {cashbackData?.sum}
          </Typography>
          {cashbackDataError && (
            <Typography fontSize="13px" color="#E42D31">
              Błąd
            </Typography>
          )}
          <Grid container sx={{ py: 1.5 }} spacing={{ xs: 2.5, md: 1 }}>
            <Grid item xs={6} md={12}>
              <Typography variant="body2" sx={{ pr: { xs: 7, md: 0 } }}>
                długi
              </Typography>
              <Typography variant="body2" sx={{ color: "#0085FF" }}>
                <strong data-testid={"funds-topayout"}>
                  {cashbackData?.toPayout}
                </strong>
              </Typography>
              {cashbackDataError && (
                <Typography fontSize="13px" color="#E42D31">
                  Błąd
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} md={12}>
              <Typography variant="body2">
                należności
              </Typography>
              <Typography variant="body2" sx={{ color: "#FF9933" }}>
                <strong data-testid={"funds-pendingtoaccept"}>
                  {cashbackData?.pendingToAccept}
                </strong>
              </Typography>
              {cashbackDataError && (
                <Typography fontSize="13px" color="#E42D31">
                  Błąd
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default AccountData;
