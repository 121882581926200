import { Box, Divider } from "@mui/material";
import React from "react";
import MainMenu from "./MainMenu";
import AccountData from "./AccountData";
import Logout from "./Logout/Logout";

const Sidebar = () => {
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        borderRadius: 1,
      }}
    >
      <AccountData />
      <Logout />
      <Divider />
      <MainMenu />
    </Box>
  );
};
export default Sidebar;
