import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import { MenuList, MenuItem, Box } from "@mui/material";
import React from "react";
import { logoutKeycloak } from "../../../../modules/AuthProvider";

type Props = {
    children?: React.ReactNode
};

const MainMenu = () => {
  const menuItems = [
    {
      route: "/",
      text: "Głowna strong",
    },
    {
      route: "tworzenie-gry",
      text: "Tworzenie gry",
    },
  ];

  return (
    <MainMenuWrapper>
      <MainMenuList>
        {menuItems.map((element, index) => (
          <CustomLink data-testid={`menu-tab-${index}`} to={element.route} key={index}>
            <MenuItem
              disableGutters={true}
              divider
              sx={{
                mb: {
                  md: 4.3,
                },
                mx: {
                  xs: 1,
                },
                whiteSpace: {
                  xs: "nowrap",
                  md: "normal",
                },
              }}
            >
                {element.text}
            </MenuItem>
          </CustomLink>
        ))}
        <MenuItem
          onClick={() => logoutKeycloak('/')}
          disableGutters={true}
          divider
          sx={{
            mb: {
              md: 4.3,
            },
            mx: {
              xs: 1,
            },
            whiteSpace: {
              xs: "nowrap",
              md: "normal",
            },
            display: {
              md: "none",
            },
          }}
        >
          Wyloguj się
        </MenuItem>
      </MainMenuList>
    </MainMenuWrapper>
  );
};

const MainMenuWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        py: {
          xs: 1.7,
          md: 4,
        },
        px: {
          xs: 0,
          md: 4,
        },
      }}
    >
      {children}
    </Box>
  );
};

const MainMenuList: React.FC<Props> = ({ children }) => {
  return (
    <MenuList
      sx={{
        display: {
          xs: "flex",
          md: "initial",
        },
        p: 0,
        px: {
          xs: 1.5,
          md: 0,
        },
        scrollbarWidth: "none",
        overflow: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {children}
    </MenuList>
  );
};

function CustomLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={to} {...props}>
      {React.cloneElement(children as JSX.Element, {
        selected: !!match,
      })}
    </Link>
  );
}

export default MainMenu;
