import React from "react";
import { Grid } from "@mui/material";
import Sidebar from "./Sidebar";
import MainContent from "./MainContent";

const MainLayout = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Sidebar />
            </Grid>
            <Grid item xs={12} md={9}>
                <MainContent />
            </Grid>
        </Grid>
    );
};

export default MainLayout;
