import { ReactKeycloakProvider } from "@react-keycloak/web"
import keycloak from "../keycloak"
import React, { useLayoutEffect, useState } from "react"
import parseJwt from "../utils/parseJwt"

type Props = {
  children?: React.ReactNode
};

const setPageAsLogged = () => {
  if (keycloak.token) {
    localStorage.setItem("kc_token", keycloak.token)
    localStorage.setItem("kc_userId", parseJwt(keycloak.token).sub)
  }
  if (keycloak.refreshToken) {
    localStorage.setItem("kc_refreshToken", keycloak.refreshToken)
  }
  document.body.classList.add("user-logged")
}

export const logoutKeycloak = (redirectUri?: string) => {
  setPageAsNotLogged()
  if (redirectUri) {
    keycloak.logout({
      redirectUri: redirectUri,
    })
  } else {
    keycloak.logout()
  }
}

const setPageAsNotLogged = () => {
  localStorage.removeItem("kc_token")
  localStorage.removeItem("kc_refreshToken")
  localStorage.removeItem("kc_userId")
  document.body.classList.remove("user-logged")
}

const isRefreshTokenExpired = () => {
  const refreshToken = localStorage.getItem("kc_refreshToken") ? localStorage.getItem("kc_refreshToken") : null
  if (!refreshToken) {
    return true
  }
  const expDate = parseJwt(refreshToken).exp * 1000

  return expDate <= Date.now()
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [ready, setReady] = useState(false)

  useLayoutEffect(() => {
    if (ready) {
      if (keycloak.token) {
        const nameBox = document.querySelector("[data-given-name]")
        if (nameBox) {
          nameBox.textContent = parseJwt(keycloak.token).given_name
        }
      }
    }
  }, [ready])

  let refreshToken = localStorage.getItem("kc_refreshToken") ? localStorage.getItem("kc_refreshToken") : null
  if (refreshToken === "undefined") {
    refreshToken = null
  }

  let token = localStorage.getItem("kc_token") ? localStorage.getItem("kc_token") : null
  if (token === "undefined") {
    token = null
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "login-required",
        refreshToken: refreshToken,
        token: token,
        checkLoginIframe: false,
      }}
      onEvent={(eventType) => {
        if (eventType === "onReady") {
          setReady(true)
          setPageAsLogged()
        }
        if (eventType === "onAuthSuccess") {
          if (!localStorage.getItem("kc_refreshToken")) {
            setPageAsLogged()
          }
        }
        if (eventType === "onAuthRefreshSuccess") {
          setPageAsLogged()
        }
        if (eventType === "onAuthError") {
          if (isRefreshTokenExpired()) {
            setPageAsNotLogged()
          }
        }
        if (eventType === "onAuthRefreshError") {
          if (isRefreshTokenExpired()) {
            setPageAsNotLogged()
          }
        }
        if (eventType === "onAuthLogout") {
          if (isRefreshTokenExpired()) {
            setPageAsNotLogged()
          }
        }
        if (eventType === "onTokenExpired") {
          keycloak.updateToken(5).then((result: boolean) => {
            if (result) {
              setPageAsLogged()
            }
          })
        }
      }}
    >
      {ready ? children : <></>}
    </ReactKeycloakProvider>
  )
}
